// General data events all pages
export const eventPageView = (pageProps, user, currentDevice) => {
  const cdl_events = {};
  cdl_events.pageTypeName = pageProps?.data?.pageFolder?.name || null;
  cdl_events.cdl_environment_country = pageProps?.locale;
  cdl_events.cdl_page_type = pageProps?.data?.pageFolder?.pageFolderType;
  cdl_events.cdl_environment_work = process.env.NODE_ENV;
  cdl_events.cdl_environment_device = currentDevice;
  if (user?.extension_attributes?.related_store_code) cdl_events.cdl_environment_shop = '109';
  if (user?.extension_attributes?.loyalty_card) cdl_events.cdl_user_loyalty_card = 'true';
  cdl_events.cdl_user_is_customer = 'true';
  if (user?.created_at) cdl_events.cdl_user_first_registration = user?.created_at;
  if (user?.updated_at) cdl_events.cdl_user_last_visit = user?.updated_at;
  cdl_events.cdl_user_cookie_acceptance = user?.extension_attributes?.optins ? 'optin' : 'output optin';
  cdl_events.cdl_user_authentication = 'none';
  if (user?.extension_attributes?.related_store_code) cdl_events.cdl_user_attachment_shop = user?.extension_attributes?.related_store_code;
  if (user?.id) cdl_events.cdl_user_id = user?.id;
  if (user?.extension_attributes?.loyalty_card.id) cdl_events.cdl_user_loyalty_card_id = user?.extension_attributes?.loyalty_card.id;
  if (user?.extension_attributes?.client_type) cdl_events.cdl_user_type = user?.extension_attributes?.client_type;
  window?.dataLayer?.push({
    event: 'pa_pageView',
    cdl_events,
  });
};

// Fin event all page

// Event to search
export const eventSearchDisplay = (rest) => {
  window?.dataLayer?.push({
    event: 'pa_searchDisplay',
    ...rest,
  });
};

export const eventListFiletr = (rest) => {
  window?.dataLayer?.push({
    event: 'pa_listFilter',
    ...rest,
  });
};

// Fin events Search

//Events products
export const eventProductPageView = (rest) => {
  window?.dataLayer?.push({
    event: 'pa_productPageView',
    ...rest,
  });
};

export const eventProductView = (rest) => {
  window?.dataLayer?.push({
    event: 'pa_productView',
    ...rest,
  });
};

export const eventProductClick = (rest) => {
  window?.dataLayer?.push({
    event: 'pa_productClick',
    ...rest,
  });
};

// Fin Events products

// Events to Cart
export const eventAddToCart = (rest) => {
  window?.dataLayer?.push({
    event: 'pa_addToCart',
    ...rest,
  });
};

export const eventCartDisplay = (rest) => {
  window?.dataLayer?.push({
    event: 'pa_cartDisplay',
    ...rest,
  });
};

// Fin events to cart

// Events checkout

export const eventCheckoutStep = (rest) => {
  window?.dataLayer?.push({
    event: 'pa_checkoutStep',
    ...rest,
  });
};

export const eventTranscationConfirmed = (rest) => {
  window?.dataLayer?.push({
    event: 'pa_transactionConfirmed',
    ...rest,
  });
};

export const eventRemoveFromCart = (rest) => {
  window?.dataLayer?.push({
    event: 'pa_removeFromCart',
    ...rest,
  });
};

// Fin events checkout
