import React from 'react';
import tailwindConfig from 'tailwind.config';
import { debounce } from 'lodash';

const devices = {
  xs: 'mobile',
  sm: 'mobile',
  md: 'mobile',
  lg: 'tablet',
  xl: 'desktop',
};

export function getCurrentDevice() {
  let device = 'desktop';

  const { screens } = tailwindConfig.theme;

  for (const breakpoint in screens) {
    if (window.innerWidth < parseInt(screens[breakpoint])) {
      device = devices[breakpoint];
      break;
    }
  }

  return device;
}

export default function useGetCurrentDevice(delay = 50) {
  const [device, setDevice] = React.useState('desktop');

  React.useEffect(() => {
    setDevice(getCurrentDevice());
    window.addEventListener(
      'resize',
      debounce(() => setDevice(getCurrentDevice()), delay),
    );
    return window.removeEventListener('resize', () => setDevice(getCurrentDevice()));
  }, []);

  return device;
}
