import React from 'react';
import { SWRConfig } from 'swr';
import { fetchApiHub } from 'frontastic/lib/fetch-api-hub';
import { FrontasticState, getFrontasticState } from './FrontasticState';
import { useEffect, useState } from 'react';

const initialState: FrontasticState = {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  useZodioAccount: {} as any,
  /* eslint-enable @typescript-eslint/no-explicit-any */
};
type User = any;
type Store = any;
interface StateContext {
  user: User;
  setUser: (newUser: User) => void;
  store: Store;
  setStore: (newStore: Store) => void;
  deleteUser: () => void;
}

export const FrontasticContext = React.createContext<FrontasticState>(initialState);
export const UserContext = React.createContext<StateContext>({ user: '', setUser: () => {}, store: '', setStore: () => {}, deleteUser: () => {} });

export const FrontasticProvider: React.FC = ({ children }) => {
  const state: FrontasticState = getFrontasticState();

  // TODO storage in backend session instead of local storage
  const getUserStorage = () => {
    return typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : '';
  };

  const getStoreStorage = () => {
    return typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('store')) : '';
  };

  const [user, setUser] = useState<any>(getUserStorage());
  const [store, setStore] = useState<any>(getStoreStorage());

  const deleteUser = () => {
    setUser('');
  };

  useEffect(() => {
    console.log('my user in local', user);
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
    localStorage.setItem('store', JSON.stringify(store));
  }, [user]);

  return (
    <SWRConfig value={{ fetcher: fetchApiHub }}>
      <UserContext.Provider value={{ user, setUser, store, setStore, deleteUser }}>
        <FrontasticContext.Provider value={state}>{children}</FrontasticContext.Provider>
      </UserContext.Provider>
    </SWRConfig>
  );
};

const checkContext = (context: FrontasticState) => {
  if (!context) {
    throw new Error('Expected to be wrapped in FrontasticProvider');
  }
};

export const useZodioAccount = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context.useZodioAccount;
};
