import React, { useContext } from 'react';
import type { AppProps } from 'next/app';
import algoliasearch from 'algoliasearch/lite';
import singletonRouter from 'next/router';
import { createInstantSearchRouterNext } from 'react-instantsearch-hooks-router-nextjs';
import { appWithTranslation } from 'next-i18next';
import { FrontasticProvider, UserContext } from 'frontastic';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import { ModalsProvider } from '@mozaic-ds/react';
import { eventPageView } from 'helpers/utils/dataLayer';
import useGetCurrentDevice from 'helpers/utils/media-queries';
import Alert from 'components/elements/Alert/Alert';

import 'styles/global.scss';

function FrontasticStarter({ Component, pageProps }: AppProps | any) {
  const currentDevice = useGetCurrentDevice();

  const { user } = useContext(UserContext);

  React.useEffect(() => {
    eventPageView(pageProps, user, currentDevice);
  }, [pageProps, user, currentDevice]);

  return (
    <FrontasticProvider>
      <ModalsProvider>
        <InstantSearch searchClient={searchClient} indexName={indexName} routing={{ router }}>
          <Component {...pageProps} />
          <Alert />
        </InstantSearch>
      </ModalsProvider>
    </FrontasticProvider>
  );
}

const appId = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID;
const apiKey = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY;
const indexName = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_SEARCH;

const algoliaClient = algoliasearch(appId, apiKey);
const router = createInstantSearchRouterNext({ singletonRouter });

const searchClient = {
  ...algoliaClient,
  search<TObject>(requests) {
    // If all requests have an indexName, we send them, else we return an empty response without requesting Algolia
    // https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react-hooks/
    return requests.every((request) => request.indexName)
      ? algoliaClient.search<TObject>(requests)
      : Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: '',
            params: '',
          })),
        });
  },
};

export default appWithTranslation(FrontasticStarter);
