import { doesAccountExist, login, createAccount, resetPassword, logout } from 'frontastic/actions/zodio/account';

export interface FrontasticState {
  useZodioAccount: typeof useZodioAccount;
}

const useZodioAccount = {
  doesAccountExist,
  login,
  createAccount,
  resetPassword,
  logout,
};

export const getFrontasticState = (): any => {
  return {
    useZodioAccount,
  };
};
