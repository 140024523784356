import React from 'react';
import { SVG } from './Picto.module.scss';

const Picto = ({ icon, className: CLASS = '', ...rest }) => {
  const [svgSource, setSvgSource] = React.useState(null);

  React.useEffect(() => {
    let isMounted = true;
    import(`./../../../public/icons/${icon}.svg`)
      .then((module) => isMounted && setSvgSource(module.default))
      .catch(console.error);

    return () => {
      isMounted = false;
    };
  }, [icon, setSvgSource]);

  try {
    const { type, props } = svgSource || {};
    const { viewBox, children } = props || {};

    const params = {
      'aria-hidden': true,
      className: [CLASS, SVG].join(' '),
      viewBox,
      ...rest,
    };

    return type ? React.createElement(type, params, children) : null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default Picto;
