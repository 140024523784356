import style from './Markdown.module.scss';
import { markdown } from 'markdown';

const Markdown = ({ content, className }) => {
  try {
    let classNames = style.Markdown;
    if (className) classNames += ` ${className}`;

    return <div className={classNames} dangerouslySetInnerHTML={{ __html: markdown.toHTML(content) }} />;
  } catch (error) {
    console.error(error);
    return <></>;
  }
};

export default Markdown;
