import React from 'react';
import CSS from 'csstype';

import useGetCurrentDevice from 'helpers/utils/media-queries';

export function Cell(props) {
  const {
    className,
    children,
    config,
  }: {
    className?: string;
    children: React.ReactNode;
    config?: {
      _type: string;
      size: number | 'viewport';
      mobile: boolean;
      tablet: boolean;
      desktop: boolean;
    };
  } = props;

  let styles: CSS.Properties;

  const currentDevice = useGetCurrentDevice(0);

  function displayOnDevice(config) {
    const { desktop, tablet, mobile } = config;
    return (desktop && currentDevice === 'desktop') || (tablet && currentDevice === 'tablet') || (mobile && currentDevice === 'mobile');
  }

  if (config.size === 'viewport') {
    styles = {
      width: '100vw',
      gridColumn: 'span 12 / span 12',
      marginLeft: '50%',
      transform: 'translateX(-50%)',
    };
  } else {
    styles = {
      gridColumn: `span ${config.size} / span ${config.size}`,
    };
  }

  return displayOnDevice(config) ? (
    <div style={styles} className={`cell${className ? ' ' + className : ''}`}>
      {children}
    </div>
  ) : null;
}
