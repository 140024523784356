import * as React from 'react';

export function Grid({
  id = '',
  tag = 'div',
  wrapperClassName = '',
  children,
}: {
  id?: string;
  tag?: 'div' | 'main';
  wrapperClassName?: string;
  children: React.ReactNode;
}) {
  const classes = wrapperClassName?.trim().length ? wrapperClassName : null;

  return tag === 'main' ? (
    <main id={id} className={classes}>
      {children}
    </main>
  ) : (
    <div id={id} className={classes}>
      {children}
    </div>
  );
}
