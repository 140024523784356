import { CreateAccount, DoesAccountExistActionRequest, DoesAccountExistActionResponse, MagentoUser } from '@Types/zodio/account';
import { fetchApiHub } from 'frontastic/lib/fetch-api-hub';
import useSWR from 'swr';

export const doesAccountExist = (email: string): Promise<DoesAccountExistActionResponse> => {
  return fetchApiHub('/action/zodio/doesAccountExist', { method: 'POST' }, { email } as DoesAccountExistActionRequest);
};
export const login = (email: string, password: string) => {
  return fetchApiHub('/action/zodio/login', { method: 'POST' }, { email, password });
};

export const logout = async () => {
  return Promise.resolve(); // en attente d'api
};

export const createAccount = (data: CreateAccount) => {
  const customer: MagentoUser = {
    customer: {
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      prefix: data.gender,
    },
    password: data.password,
  };
  return fetchApiHub('/action/zodio/createNewAccount', { method: 'POST' }, customer);
};

export const resetPassword = (data: any) => {
  return fetchApiHub('/action/zodio/resetPassword', { method: 'POST' }, data);
};
