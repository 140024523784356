import React from 'react';
import { Cell } from './cell';
import { highlightClassNames, TasticWrapper } from './component';
import { Errors } from './errors';
import { Grid } from './grid';
import { Cell as CellType, Tastic, TasticRegistry, PageDataResponse } from './types';
import BackToTop from 'components/elements/BackToTop/BackToTop';

import useGetCurrentDevice from 'helpers/utils/media-queries';

export function FrontasticRenderer({
  data,
  tastics = {},
  wrapperClassName = '',
  currentHighlight,
}: {
  data: PageDataResponse;
  tastics: TasticRegistry;
  wrapperClassName?: string;
  currentHighlight?: string;
}) {
  const headerClasses = [wrapperClassName, highlightClassNames(currentHighlight === 'head')].join(' ');
  const mainClasses = ['container', wrapperClassName, highlightClassNames(currentHighlight === 'main')].join(' ');
  const footerClasses = [wrapperClassName, highlightClassNames(currentHighlight === 'footer')].join(' ');

  const currentDevice = useGetCurrentDevice(0);

  return (
    <div id="global-container">
      {process && process.env.NODE_ENV !== 'production' && <Errors />}

      {/* HEADER CONTAINER */}
      <Grid id="header-container" wrapperClassName={headerClasses}>
        {data?.page?.sections?.head?.layoutElements.map((layoutElement: CellType) => (
          <Cell
            config={layoutElement.configuration}
            className={highlightClassNames(currentHighlight === layoutElement.layoutElementId)}
            key={layoutElement.layoutElementId + currentDevice}
          >
            {layoutElement.tastics.map((t: Tastic) => (
              <TasticWrapper
                tastics={tastics}
                key={t.tasticId + currentDevice}
                data={t}
                dataSources={data.data.dataSources}
                pageFolder={data.pageFolder}
                highlight={currentHighlight === t.tasticId}
              />
            ))}
          </Cell>
        ))}
      </Grid>

      {/* MAIN CONTAINER */}
      <Grid tag="main" id="main-container" wrapperClassName={mainClasses}>
        {data?.page?.sections?.main?.layoutElements.map((layoutElement: CellType) => (
          <Cell
            config={layoutElement.configuration}
            className={highlightClassNames(currentHighlight === layoutElement.layoutElementId)}
            key={layoutElement.layoutElementId + currentDevice}
          >
            {layoutElement.tastics.map((t: Tastic) => (
              <TasticWrapper
                tastics={tastics}
                key={t.tasticId + currentDevice}
                data={t}
                dataSources={data.data.dataSources}
                pageFolder={data.pageFolder}
                highlight={currentHighlight === t.tasticId}
              />
            ))}
          </Cell>
        ))}
      </Grid>

      {/* FOOTER CONTAINER */}
      <Grid id="footer-container" wrapperClassName={footerClasses}>
        {data?.page?.sections?.footer?.layoutElements.map((layoutElement: CellType) => (
          <Cell
            config={layoutElement.configuration}
            className={highlightClassNames(currentHighlight === layoutElement.layoutElementId)}
            key={layoutElement.layoutElementId + currentDevice}
          >
            {layoutElement.tastics.map((t: Tastic) => (
              <TasticWrapper
                tastics={tastics}
                key={t.tasticId + currentDevice}
                data={t}
                dataSources={data.data.dataSources}
                pageFolder={data.pageFolder}
                highlight={currentHighlight === t.tasticId}
              />
            ))}
          </Cell>
        ))}
      </Grid>

      <BackToTop />
    </div>
  );
}
