import React from 'react';
import ErrorBoundary from './error-boundary';
import { TasticWrapperProps } from './types';
import { injectDataSources } from './utils/inject-data-sources';

import useGetCurrentDevice from 'helpers/utils/media-queries';

export function highlightClassNames(highlight: boolean) {
  return highlight ? 'outline outline-dashed outline-2 outline-accent-400' : '';
}

export function TasticWrapper(props: TasticWrapperProps) {
  const { tastics, data, dataSources, highlight = false, previewId = undefined } = props;
  const TasticToRender: React.ElementType = tastics[data.tasticType] || tastics['default'];

  // inject all datasources into the proper nodes
  // dataSources null check satisfies TS
  const updatedBlock = dataSources ? injectDataSources(data.configuration, dataSources) : data.configuration;

  const currentDevice = useGetCurrentDevice(0);

  function displayOnDevice(config) {
    const { desktop, tablet, mobile } = config;
    return (desktop && currentDevice === 'desktop') || (tablet && currentDevice === 'tablet') || (mobile && currentDevice === 'mobile');
  }

  return displayOnDevice(data.configuration) ? (
    <div className={`component${highlight ? ' ' + highlightClassNames(highlight) : ''}`}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ErrorBoundary>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <TasticToRender type={data?.tasticType} id={data?.tasticId} data={updatedBlock} pageFolder={props.pageFolder} previewId={previewId} />
      </ErrorBoundary>
    </div>
  ) : null;
}
