import React from 'react';
import style from './Alert.module.scss';
import Picto from 'components/elements/Picto/Picto';
import { toast, useToaster } from 'react-hot-toast/headless';
import Markdown from '../contentful/MarkDown/MarkDown';

const Alert = () => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause } = handlers;

  return (
    <div className={style.Alert} onMouseEnter={startPause} onMouseLeave={endPause}>
      {toasts
        .filter((T) => T.visible)
        .map((T) => {
          const { id, type, message, duration } = T;

          if (duration !== Infinity)
            setTimeout(() => {
              toast.dismiss(id);
            }, duration);

          return (
            <div key={id} className={style.Alert__item} data-type={type} role="alert" aria-live="polite">
              <Markdown content={message} />

              {duration === Infinity && (
                <button className={style.Alert__item__close} onClick={() => toast.dismiss(id)}>
                  <Picto icon="cross" />
                </button>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default Alert;
