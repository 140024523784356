import React from 'react';
import Picto from 'components/elements/Picto/Picto';
import style from './BackToTop.module.scss';
import { debounce } from 'lodash';

const BackToTop = () => {
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      'scroll',
      debounce(() => setVisible(document.documentElement.scrollTop > 1000), 50),
    );
    return () => window.removeEventListener('scroll', () => setVisible(false));
  }, []);

  return (
    <button
      data-visible={visible}
      className={style.BackToTop}
      onClick={() => window.scrollTo(0, 0)}
      aria-label="Cliquez pour remonter en haut de page"
    >
      <Picto icon="zodio-arrow--top" />
      <span aria-hidden="true">HAUT</span>
    </button>
  );
};

export default BackToTop;
